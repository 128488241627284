import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile, tablet } from '~styles/global'
import { sentenceCase } from 'change-case'
import useSiteSettings from '~utils/useSiteSettings'

const ContactForm = ({ className, formName, successMessage }) => {
  
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)
	const [sendError, setSendError] = useState(false)

	const { contactFormEmailDestination } = useSiteSettings()

	console.log(contactFormEmailDestination)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		const msg = {
			to: contactFormEmailDestination,
			from: {
				email: 'mail@cubefg.com.au',
				name: 'Contact Form'
			},
			subject: 'New Message',
			template_id: 'd-699827f9c4da406b84cb0e6f067d9200',
			dynamic_template_data: {
				subject: 'New Message',
				headline: 'New message form your contact form',
				fields: Object.keys(values).map(key =>({label: sentenceCase(key), value: values[key]}))
			},
		}
 
		axios.post('/api/trigger-sendgrid', msg)
			.then(res => {
				console.log(res)
				setSuccess(true)
			})
			.catch(err => {
				console.log(err)
				setSendError(true)
			}
			)
	}

	const errorFields = errors.map(error => error.field)

	return (
		<div className={className}>
			<Relative>
				<Success css={css`
          opacity: ${success ? '1' : '0'};
          pointer-events: ${success ? 'inherit' : 'none'};
        `}>
					{successMessage}
				</Success>
				<Form method="POST" name={formName} noValidate onSubmit={e => validate(e)} css={css`
            opacity: ${success ? '0' : '1'};
            pointer-events: ${success ? 'none' : 'inherit'};
          `}>
					<Input
						type="text"
						placeholder="Name"
						id="name"
						name="name"
						onChange={e => setValues({...values, name: e.target.value})}
					/>
					<Input
						type="email"
						id="email"
						name="email"
						placeholder="Email"
						onChange={e => setValues({...values, email: e.target.value})}
					/>
					<Input
						type="phone"
						id="phone"
						name="phone"
						placeholder="Phone no."
						onChange={e => setValues({...values, phone: e.target.value})}
					/>
					<InputFlexi
						type="postcode"
						id="postcode"
						name="postcode"
						placeholder="Postcode"
						onChange={e => setValues({...values, postcode: e.target.value})}
					/>
					<Submit onClick={() => null} type="submit">
            Submit
					</Submit>
				</Form>
				{errors?.length > 0 &&
				<Errors className='small'>
					{errors.map(error => <p className="h5">{error.message}</p>)}
				</Errors>
				}
			</Relative>
		</div>
	)
}

const Success = styled.h4`
	font-weight: 300;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s 0.3s;
	color: var(--white);
`

const Form = styled.form`
  transition: opacity 0.3s;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	border: 1px solid;
	border-color: var(--extraDarkGrey);
	border-radius: 10px;
	overflow: hidden;
	${mobile}{
		border-radius: 6px;
	}
`
const Input = styled.input`
	display: block;
	width: 100%;
	height: 48px;
	border-style: solid;
	border-width: 1px;
	border: none;
	border-bottom: 1px solid;
	border-color: var(--extraDarkGrey);
	color: var(--extraDarkGrey);
	padding: 16px;
	box-sizing: border-box;
	font-family: inherit;
	::placeholder{
		color: var(--extraDarkGrey);
		font-family: inherit;
		opacity: 1;
	}
	${mobile}{
		height: 44px;
	}
`
const InputFlexi = styled(Input)`
	flex: 1;
	border-bottom: none;
	min-width: 100px;
`
const Submit = styled.button`
	width: 150px;
	background-color: var(--black);
	color: var(--white);
	font-weight: 400;	
	${mobile}{
		width: 92px;
	}
`
const Errors = styled.div`
  grid-column: span 2;
  color: var(--white);
	position: relative;
	padding-top: 12px;	
	top: 0;
	${mobile}{
		padding-top: 10px;
	}
`
const Relative = styled.div`
	position: relative;
`
ContactForm.propTypes = {
	formName: PropTypes.string.isRequired,
	className: PropTypes.string,
	successMessage: PropTypes.array,
}

export default ContactForm
